import {
  type DefaultValue,
  type SubmissionResult,
  useForm as useConform,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useCallback, useEffect } from 'react'
import {
  useActionData,
  useFetcher,
  useNavigation,
  useSubmit,
} from 'react-router'
import { z } from 'zod'
import type { ActionResult } from '~/utils/actions.server'

const IntentSchema = z.string()

type UseConformProps<T extends Record<string, any>> = Parameters<
  typeof useConform<T>
>[0]

type UseFormProps<Schema extends z.ZodObject<any>> = {
  schema: Schema
  useFetcher?: boolean
  fetcherKey?: string
  onSuccess?: () => void
} & UseConformProps<z.infer<Schema>>

export function useForm<Schema extends z.ZodObject<any>>({
  schema,
  useFetcher: submitWithFetcher = true,
  fetcherKey: explicitFetcherKey,
  shouldValidate = 'onSubmit',
  onSuccess = () => undefined,
  defaultValue,
  ...props
}: UseFormProps<Schema>) {
  const navigation = useNavigation()
  const actionData = useActionData<ActionResult<unknown>>()
  const submit = useSubmit()
  const intent = IntentSchema.parse(schema.shape.intent.value)
  const fetcherKey = explicitFetcherKey || intent
  const fetcher = useFetcher<ActionResult<unknown>>({ key: fetcherKey })

  const data = submitWithFetcher ? fetcher.data : actionData
  const formData = submitWithFetcher ? fetcher.formData : navigation.formData
  const state = submitWithFetcher ? fetcher.state : navigation.state

  const isSubmitting = state !== 'idle' && formData?.get('intent') === intent

  const lastResult = hasSubmissionResult(data) ? data.submission : null

  const [form, fields] = useConform<z.infer<Schema>>({
    ...props,
    lastResult: isSubmitting ? null : lastResult,
    shouldValidate,
    defaultValue: {
      ...((defaultValue ?? {}) as Partial<z.infer<Schema>>),
      intent,
      fetcherKey,
    } as DefaultValue<z.TypeOf<Schema>>,
    constraint: getZodConstraint(schema),
    onValidate({ formData }) {
      const result = parseWithZod(formData, { schema })
      if (result.status === 'error') {
        console.warn(result.error)
      }
      return result
    },
    onSubmit(e, context) {
      e.preventDefault()
      const submitFn = submitWithFetcher ? fetcher.submit : submit
      submitFn(context.formData, {
        method: context.method,
        action: context.action,
        encType: context.encType,
      })
    },
  })

  const onSuccessCallback = useCallback(onSuccess, [])

  useEffect(() => {
    if (data?.ok) {
      onSuccessCallback()
    }
  }, [data, onSuccessCallback])

  return [form, fields] as const
}

export function hasSubmissionResult(
  value: unknown
): value is { submission: SubmissionResult } {
  if (!value || typeof value !== 'object') return false
  const maybeSubmission = (value as any).submission
  if (!maybeSubmission || typeof maybeSubmission !== 'object') return false
  return true
}
