import type { ComponentProps } from 'react'
import { cn } from '#lib/utils.js'

export const ErrorMessage = ({
  className,
  ...props
}: ComponentProps<'span'>) => {
  return (
    <span
      className={cn(
        'text-destructive-700 bg-destructive-100 py-1 px-2 rounded-sm text-xs font-medium w-fit block',
        className
      )}
      {...props}
    />
  )
}
