import {
  type FormMetadata,
  FormProvider,
  getFormProps,
  useFormMetadata,
} from '@conform-to/react'
import { ErrorMessage } from '@repo/ui/components/ErrorMessage.js'
import type { ComponentProps, PropsWithChildren } from 'react'
import { Form as RemixForm } from 'react-router'
import { z } from 'zod'
import { Checkbox } from './Checkbox'
import { Field, FieldErrors, FieldHint, Label } from './Field'
import { FieldList } from './FieldList'
import { HiddenInput, Input, Select, Textarea } from './Inputs'
import { MultiSelect } from './MultiSelect'
import { SubmitButton } from './SubmitButton'
import { ToggleGroup } from './ToggleGroup'

type FormProps = {
  form: FormMetadata<any>
  formKey?: string
} & ComponentProps<typeof RemixForm>

const FormInitialValueSchema = z
  .object({ intent: z.string(), fetcherKey: z.string() })
  .passthrough()

export const useInitialValues = (form: FormMetadata<any>) => {
  return FormInitialValueSchema.parse(form.initialValue)
}

const Form = ({
  children,
  method = 'POST',
  action,
  form,
  formKey,
  ...props
}: PropsWithChildren<FormProps>) => {
  return (
    <FormProvider context={form.context}>
      <RemixForm
        key={formKey}
        {...getFormProps(form)}
        method={method}
        action={action}
        {...props}
      >
        <HiddenInput name="intent" />
        <HiddenInput name="fetcherKey" />
        {children}
        <FormErrors className="mt-2" />
      </RemixForm>
    </FormProvider>
  )
}

const FormErrors = ({ ...props }: ComponentProps<'div'>) => {
  const form = useFormMetadata()
  if (!form.errors?.some(Boolean)) return null

  return (
    <div className="space-y-1" {...props}>
      {form.errors.map((error, i) => {
        return <ErrorMessage key={String(i)}>{error}</ErrorMessage>
      })}
    </div>
  )
}

Form.Field = Field
Form.Label = Label
Form.FieldErrors = FieldErrors
Form.Input = Input
Form.Textarea = Textarea
Form.HiddenInput = HiddenInput
Form.Select = Select
Form.Checkbox = Checkbox
Form.MultiSelect = MultiSelect
Form.FieldHint = FieldHint
Form.FieldList = FieldList
Form.ToggleGroup = ToggleGroup
Form.SubmitButton = SubmitButton

export { Form }
