import { type FieldName, useField, useInputControl } from '@conform-to/react'
import {
  ToggleGroup as UIToggleGroup,
  ToggleGroupItem as UIToggleGroupItem,
} from '@repo/ui/components/ToggleGroup.js'
import type { ComponentProps } from 'react'
import { useFieldName } from './Field'

type ToggleGroupOption = {
  value: string
  label: string
}

type SingleToggleGroupProps = {
  type: 'single'
} & Omit<
  ComponentProps<typeof UIToggleGroup>,
  'type' | 'value' | 'onValueChange'
>

type MultipleToggleGroupProps = {
  type: 'multiple'
} & Omit<
  ComponentProps<typeof UIToggleGroup>,
  'type' | 'value' | 'onValueChange'
>

type ToggleGroupProps = SingleToggleGroupProps | MultipleToggleGroupProps

const ToggleGroup = ({ className, type, children }: ToggleGroupProps) => {
  const fieldName = useFieldName() as FieldName<string | string[]>
  const [meta] = useField(fieldName)
  const control = useInputControl(meta)

  if (type === 'multiple') {
    return (
      <UIToggleGroup
        key={meta.key}
        type="multiple"
        className={className}
        value={control.value as string[] | undefined}
        onValueChange={control.change}
      >
        {children}
      </UIToggleGroup>
    )
  }

  return (
    <UIToggleGroup
      key={meta.key}
      type="single"
      className={className}
      value={control.value as string | undefined}
      onValueChange={control.change}
    >
      {children}
    </UIToggleGroup>
  )
}

const ToggleGroupItem = <T extends ToggleGroupOption>({
  option,
  children,
  ...props
}: Omit<ComponentProps<typeof UIToggleGroupItem>, 'value'> & { option: T }) => {
  return (
    <UIToggleGroupItem {...props} value={option.value}>
      {children}
    </UIToggleGroupItem>
  )
}

ToggleGroup.Item = ToggleGroupItem

export { ToggleGroup }
