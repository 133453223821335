import { type FieldName, getFieldsetProps, useField } from '@conform-to/react'
import { ErrorMessage } from '@repo/ui/components/ErrorMessage.js'
import { Label as UILabel } from '@repo/ui/components/Label.js'
import { cn } from '@repo/ui/lib/utils'
import { type ComponentProps, createContext, useContext } from 'react'

type ValidFieldName = FieldName<unknown>

const FieldNameContext = createContext<ValidFieldName | undefined>(undefined)

export const useFieldName = () => {
  const context = useContext(FieldNameContext)
  if (!context) throw new Error('useFieldName must be used within <Field />')
  return context
}

export const Field = ({
  className,
  name,
  ...props
}: ComponentProps<'fieldset'> & { name: ValidFieldName }) => {
  const [meta] = useField(name)

  return (
    <FieldNameContext value={name}>
      <fieldset
        className={cn('space-y-1.5', className)}
        {...props}
        {...getFieldsetProps(meta)}
      />
    </FieldNameContext>
  )
}

export const Label = ({ ...props }: ComponentProps<typeof UILabel>) => {
  const name = useFieldName()
  const [meta] = useField(name)
  return <UILabel htmlFor={meta.id} {...props} />
}

export const FieldHint = ({ className, ...props }: ComponentProps<'small'>) => {
  return (
    <small
      className={cn('block text-muted-foreground text-xs', className)}
      {...props}
    />
  )
}

export const FieldErrors = ({ ...props }: ComponentProps<'div'>) => {
  const name = useFieldName()
  const [meta] = useField(name)
  if (!meta.errors?.length) return null

  return (
    <div className="space-y-1" {...props}>
      {meta.errors.map((error, i) => (
        <ErrorMessage key={String(i)}>{error}</ErrorMessage>
      ))}
    </div>
  )
}
