import { type FieldName, useField, useInputControl } from '@conform-to/react'
import {
  type MultiSelectOption,
  MultiSelect as UIMultiSelect,
} from '@repo/ui/components/MultiSelect'
import { type ComponentProps, useMemo } from 'react'
import { useFieldName } from './Field'

type FormMultiSelectProps<T extends MultiSelectOption> = Omit<
  ComponentProps<typeof UIMultiSelect<T>>,
  'onSelect' | 'selectedOptions'
>

const MultiSelect = <T extends MultiSelectOption>({
  options,
  children,
  onSearch,
  renderOption,
  renderSelectedOption,
  ...props
}: FormMultiSelectProps<T>) => {
  const fieldName = useFieldName() as FieldName<string[]>
  const [meta] = useField(fieldName)
  const control = useInputControl(meta)

  if (typeof control.value === 'string')
    throw new Error('MultiSelect value must be an array')

  const handleSelect = (value: T['value'], isSelected: boolean) => {
    if (typeof control.value === 'string')
      throw new Error('MultiSelect value must be an array')
    if (isSelected) {
      return control.change([...(control.value || []), String(value)])
    }
    return control.change(control.value?.filter((v) => v !== value))
  }

  const selectedOptions = useMemo(
    () =>
      options.filter((option) => control.value?.includes(String(option.value))),
    [control.value, options]
  )

  return (
    <UIMultiSelect
      {...props}
      key={meta.key}
      options={options}
      selectedOptions={selectedOptions}
      onSearch={onSearch}
      onSelect={handleSelect}
      renderOption={renderOption}
      renderSelectedOption={renderSelectedOption}
    >
      {children}
    </UIMultiSelect>
  )
}

MultiSelect.Trigger = UIMultiSelect.Trigger
MultiSelect.TriggerPlaceholder = UIMultiSelect.TriggerPlaceholder
MultiSelect.Content = UIMultiSelect.Content
MultiSelect.Options = UIMultiSelect.Options
MultiSelect.Search = UIMultiSelect.Search
MultiSelect.Empty = UIMultiSelect.Empty
MultiSelect.SelectedOptions = UIMultiSelect.SelectedOptions

export { MultiSelect }
