import { useFormMetadata } from '@conform-to/react'
import { Button } from '@repo/ui/components/Button.js'
import type { ComponentProps } from 'react'
import { useFetcher, useNavigation } from 'react-router'
import { useInitialValues } from './Form'

export const SubmitButton = ({ ...props }: ComponentProps<typeof Button>) => {
  const form = useFormMetadata()
  const { intent, fetcherKey } = useInitialValues(form)
  const nav = useNavigation()
  const fetcher = useFetcher({ key: fetcherKey })
  const isSubmitting =
    nav.state !== 'idle' && nav.formData?.get('intent') === intent
  const isFetching =
    fetcher.state !== 'idle' && fetcher.formData?.get('intent') === intent

  return (
    <Button type="submit" loading={isSubmitting || isFetching} {...props} />
  )
}
