import {
  type FieldMetadata,
  type FieldName,
  getInputProps,
  useField,
  useFormMetadata,
} from '@conform-to/react'
import { type ComponentProps, createContext, useContext } from 'react'
import { useFieldName } from './Field'

type FieldListProps = {
  renderListItem: ({
    item,
    onRemove,
  }: { item: string; onRemove: () => void }) => React.ReactNode
}

type FieldListContextType = {
  name: FieldName<string[]>
  renderListItem: FieldListProps['renderListItem']
  items: FieldMetadata<string>[]
}

const FieldListContext = createContext<FieldListContextType | undefined>(
  undefined
)

const useFieldList = () => {
  const context = useContext(FieldListContext)
  if (!context)
    throw new Error('useFieldList must be used within a <FieldList />')
  return context
}

const FieldList = ({
  renderListItem,
  ...props
}: FieldListProps & ComponentProps<'div'>) => {
  const name = useFieldName() as FieldName<string[]>
  const [meta] = useField(name)

  const fieldList = meta.getFieldList()

  return (
    <FieldListContext value={{ name, renderListItem, items: fieldList }}>
      <div {...props} />
    </FieldListContext>
  )
}

const FieldListItems = () => {
  const meta = useFormMetadata()
  const { name, items, renderListItem } = useFieldList()

  return items.map((item, index) => {
    if (!item.value) return null
    return (
      <div key={item.key}>
        <input {...getInputProps(item, { type: 'hidden' })} key={item.key} />
        {renderListItem({
          item: item.value,
          onRemove: () => meta.remove({ name, index }),
        })}
      </div>
    )
  })
}

FieldList.Items = FieldListItems

export { FieldList }
